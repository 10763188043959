import { createAction, props } from '@ngrx/store';

import { DocsIdType } from './docs-id.reducer';

export enum DocsIdActions {
    SetData = `[DocsId] Set Data`,
}

export const setDocsIdData = createAction(
    DocsIdActions.SetData,
    props<{ payload: DocsIdType }>()
);
