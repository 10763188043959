import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { ACL } from '@proman/services/acl.service';
import { isDefined } from '@proman/utils';
import { PromanStateService } from '@frontend/shared/services/proman-state.service';
import { UserResolver } from '../app-resolvers';
import { ToastService } from '@proman/services/toast.service';
import { take } from '@proman/rxjs-common';
import { Store } from '@ngrx/store';
import { loadCurrUser } from '@proman/store/curr-user';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class GuardsAclService implements CanActivate {
    constructor(
        private ACL: ACL,
        private PromanState: PromanStateService,
        private Toast: ToastService,
        private Router: Router,
        private store: Store,
        @Inject(UserResolver) private userResolver: UserResolver,
    ) {
    }

    async canActivate(
        route: ActivatedRouteSnapshot,
    ): Promise<boolean> {

        if (!isDefined(this.ACL.permissions)) {
            await this.userResolver.resolve();

        }
        const data = route.data;

        if (!Object.keys(this.ACL.permissions || {}).length) {
            this.store.dispatch(loadCurrUser());
            await new Promise<void>((resolve) => {
               this.ACL.isLoaded$
                   .pipe(take(1))
                   .subscribe(() => {
                     resolve();
                   });
            });
        }

        const isAllowed = isDefined(data.acl) ? this.ACL.check(data.acl) : true;
        if (!isAllowed) {
            const noAclMsg = `ACL disabled: '${data.acl}'`
            console.warn(noAclMsg);
            this.Toast.pop('error', noAclMsg);
            if (data.redirect) this.PromanState.to(data.redirect);
            if (this.Router.url.match('/')) {
                this.PromanState.go();
            }

        }
        return isAllowed;

    }
}
