import { createAction, props } from '@ngrx/store';
import { Currency, UserMenu, UserMenuGroup } from '../../interfaces/entity-interfaces';
import { Money } from '../../interfaces/common.interface';
import { CurrUser } from '../../interfaces/object-interfaces';

export interface CurrencyData {
    data: Currency[];
    getZeroPrice?: (currency?: string) => Money;
    base?: Currency;
    dataMappedByName?: { [key: string]: Currency };
    dataMappedById?: { [key: number]: Currency };
    zeroPrice?: Money;
}

export interface CurrUserStateType {
    data: CurrUser;
    entityFilters: { [entity: string]: any };
    uiPrefs?: { [key: string]: boolean };
    userMenu: { unassigned: UserMenu[], groups: UserMenuGroup[] };
}

export enum CurrencyActions {
    SetCurrency = `[Currency] Set Currency`,
    LoadCurrency = `[Currency] Load Currency`,
}

export const setCurrency = createAction(
    CurrencyActions.SetCurrency,
    props<{ payload: Currency[] }>()
);

export const loadCurrency = createAction(
    CurrencyActions.LoadCurrency
);
