import { Action, createReducer, on } from '@ngrx/store';
import { setDocsIdData } from './docs-id.actions';

export interface DocsIdValues {
    title: string;
    firstParagraph: string;
    path: string;
}

export interface DocsIdType {
    [key: string]: DocsIdValues;
}

export interface DocsIdData {
    data: DocsIdType;
}

export const initialState: DocsIdData = {
    data: {}
};

export const docsIdReducer = createReducer(
    initialState,
    on(setDocsIdData, (state: DocsIdData, action) => ({ data: action.payload }),
    )
);

export function reducer(state: DocsIdData, action: Action): DocsIdData {
    return docsIdReducer(state, action);
}
