import { Action, createReducer, on } from '@ngrx/store';
import { CurrencyData, setCurrency } from './currency.actions';

export const initialState: CurrencyData = {
    data: [],
};

export const currencyReducer = createReducer(
    initialState,
    on(setCurrency, (state: CurrencyData, action) => {
        return {  ...state, data: action.payload }
    })
);

export function reducer(state: CurrencyData, action: Action): CurrencyData {
    return currencyReducer(state, action);
}
