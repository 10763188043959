import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DocsIdType } from './docs-id.reducer';

export const docsIdKey: string = 'docsId';

export const selectDocsIdState = createFeatureSelector<DocsIdType>(docsIdKey);

export const getDocsIdData = createSelector(
    selectDocsIdState, (state: DocsIdType) => state.data,
);
